import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Tooltip, } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../locales';
import { getCheckedOptions } from '../../redux/slices/reservation';
import { dispatch } from '../../redux/store';
import Iconify from '../iconify';
export function RHFCheckbox({ name, helperText, label, ...other }) {
    const { control } = useFormContext();
    const { translate } = useLocales();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(name)}`, children: _jsx(FormControlLabel, { label: `${translate(label)}`, control: _jsx(Checkbox, { ...field, checked: field.value }), ...other }) }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? `${translate(error?.message)}` : `${translate(helperText)}` }))] })) }));
}
export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, values, ...other }) {
    const { control } = useFormContext();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { translate } = useLocales();
    const handleCheckboxChange = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((value) => value !== option));
        }
        else {
            setSelectedOptions([...selectedOptions, option]);
        }
        dispatch(getCheckedOptions(selectedOptions));
    };
    return (_jsx(Controller, { name: name, control: control, defaultValue: [], render: ({ field, fieldState: { error } }) => (_jsxs(FormControl, { component: "fieldset", children: [label && (_jsx(FormLabel, { component: "legend", sx: { typography: 'body2' }, children: label })), _jsx(FormGroup, { sx: {
                        ...(row && {
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                        }),
                        '& .MuiFormControlLabel-root': {
                            '&:not(:last-of-type)': {
                                mb: spacing || 0,
                            },
                            ...(row && {
                                mr: 0,
                                '&:not(:last-of-type)': {
                                    mr: spacing || 2,
                                },
                            }),
                        },
                    }, children: options.map((option) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: selectedOptions && selectedOptions?.includes(option.value), onChange: () => handleCheckboxChange(option.value) }), label: `${translate(option.label)} `, ...other }, option.value))) }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', mr: '10px', color: '#9AA6B2' } }) }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { mx: 0 }, children: error ? error?.message : helperText }))] })) }));
}
